/** ****************************************************************************
		███╗   ██╗███████╗██████╗  █████╗  ██████╗
		████╗  ██║██╔════╝██╔══██╗██╔══██╗██╔═══██╗
		██╔██╗ ██║█████╗  ██║  ██║███████║██║   ██║
		██║╚██╗██║██╔══╝  ██║  ██║██╔══██║██║   ██║
		██║ ╚████║███████╗██████╔╝██║  ██║╚██████╔╝
		╚═╝  ╚═══╝╚══════╝╚═════╝ ╚═╝  ╚═╝ ╚═════╝

@description Affichage et liste des articles.

@author Victor Audetat <audetat.v@gmail.com>

@copyright © 2020  NEDAO Association (info@nedao.ch)
@see {@link https://nedao.ch}

@license
	This program is free software: you can redistribute it and/or modify
	it under the terms of the GNU Affero General Public License as published
	by the Free Software Foundation, either version 3 of the License, or
	(at your option) any later version.

	This program is distributed in the hope that it will be useful,
	but WITHOUT ANY WARRANTY; without even the implied warranty of
	MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
	GNU Affero General Public License for more details.

	You should have received a copy of the GNU Affero General Public License
	along with this program.  If not, see <https://www.gnu.org/licenses/>.
*******************************************************************************/

import React from 'react';
import {GetNews} from './../components/graphql/getNews';
import ArticleCard from './../components/articles/article-card';
import Layout from './../components/template/layout';

const News = () => {
	const articles = GetNews();
	const isArticles = articles.length > 0 ?	 true : false;
	const currentTime = new Date().getTime();

	return (
		<Layout 
			title="Articles"
			lang="fr"
			description="Liste des articles"
			social={
				{
					type: 'website',
				}
			}
		>
			<h1>Actualités</h1>
			{isArticles &&
			<div className="article-page page">
				{articles.map((article, key) => (
					new Date(article.node.frontmatter.date).getTime() < currentTime &&
					<ArticleCard
						key={key}
						chapo={article.node.frontmatter.chapo}
						author={article.node.frontmatter.author}
						title={article.node.frontmatter.title}
						date={article.node.frontmatter.date}
						body={article.node.html}
						categorie={article.node.frontmatter.categorie}
						id={article.node.frontmatter.id}
						image={article.node.frontmatter.featuredImage.childImageSharp.fixed}
					/> 
				))}
			</div>}
			{!isArticles &&
				<p> Aucun article à afficher</p>
			}

		</Layout>
	);
};

export default News;